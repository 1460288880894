<!-- eslint-disable vue/no-v-html -->
<template>
	<div
		class="w-100 cart"
		:style="themeColors">
		<div
			:style="{ marginTop: topOffset + 'px' }"
			:class="['xs','sm'].includes(windowWidth) ? 'h-80' : 'h-55'" />
		<div
			:class="['xs','sm'].includes(windowWidth) ? 'py-5' : 'p-5'"
			class="border-0 bg-white mb-0">
			<!-- Back to store link -->
			<div
				class="row mx-auto mb-5 container">
				<div class="col-12">
					<a @click="$router.push({ name: 'Store' })">
						<div class="btn btn-link pointer text-decoration-none p-0">
							<div class="branded-text">
								<i class="fas fa-chevron-left mr-2" />
								<span class="font-weight-bold">{{ translate('back_to_store') }}</span>
							</div>
						</div>
					</a>
				</div>
			</div>
			<!-- Main product -->
			<div v-if="mainPromoLoading">
				<is-loading
					:loading="mainPromoLoading"
					:loading-label="translate('loading')"
					:no-data-label="translate('data_not_found')" />
			</div>
			<div v-else>
				<!-- Image / data -->
				<div
					:class="['xs','sm', 'md'].includes(windowWidth) ? ' px-3' : 'px-5'"
					class="row mx-auto container mb-5">
					<!-- Image Component -->
					<div
						class="col-12 col-md-6"
						:style="['xs', 'sm'].includes(windowWidth) ? 'display: none': ''"
						:class="['xs','sm', 'md'].includes(windowWidth) ? ' px-3' : 'px-5'">
						<image-carousel
							modal-id="desktop"
							:images="imgCarousel"
							:group-to-show="getGroupToShow"
							style="--product-primary-color: var(--theme-primary-color);"
							highlight-color="var(--theme-primary-color)"
							:enable-zoom="true" />
					</div>
					<!-- Product price/sizes/colors/qty -->
					<div class="col-12 col-md-6">
						<!-- Product name / price -->
						<p
							class="h1 font-weight-bold branded-text"
							:class="{ 'm-0': subtitle().length > 0 }">
							{{ computedTitle }}
						</p>
						<p class="h4 text-uppercase font-weight-bold branded-text">
							{{ subtitle() }}
						</p>
						<!-- Prices -->
						<p
							:class="{'mb-0': mainHasExchange}">
							<s
								v-if="computedPromoPrice.length > 0"
								class="h5 font-weight-bold text-muted"
								style="opacity: 0.7">
								{{ computedPrice }}
							</s>
							<template v-else-if="feesInfo && feesInfo.show_fee">
								<template v-if="feesInfo.show_only_text">
									<span
										class="h3 font-weight-bold branded-text">
										{{ feesInfo.price_with_fee.formatted_amount }}
									</span>
									<span class="text-muted">
										{{ translate(`plus_${feesInfo.code_name}`) }}
									</span>
								</template>
								<template v-else>
									<span
										class="h3 font-weight-bold branded-text">
										{{ feesInfo.price_with_fee.formatted_amount }}
									</span>
									<p class="text-muted mb-0">
										{{ computedPrice }} {{ renderDefaultFee(feesInfo.default) }}
										<i
											v-if="feesInfo.variations.length"
											v-b-tooltip.hover.top
											:title="feeVariationsText(feesInfo.variations)"
											class="fas fa-info-circle" />
									</p>
									<p
										v-for="(fee, index) in feesInfo.whole_cart"
										:key="index"
										class="text-muted">
										{{ translate(`plus_fee_whole_cart_${fee.operator}`, { fee: fee.formatted_amount, qty: fee.qty }) }}
									</p>
								</template>
							</template>
							<template v-else>
								<span
									class="h3 font-weight-bold branded-text">
									{{ computedPrice }}
								</span>
							</template>
						</p>
						<p
							v-if="computedPromoPrice.length > 0">
							<span class="h3 font-weight-bold branded-text">
								{{ computedPromoPrice }}
							</span>
						</p>
						<div class="h6 text-muted">
							{{ computedPriceInfo }}
						</div>
						<div
							v-if="feesInfo && feesInfo.show_fee && mainHasExchange"
							class="mx-auto exchange-text text-center medium d-flex align-items-end">
							<p class="my-0">
								{{ mainExchangePrice }} {{ renderDefaultFeeExchange(feesInfo.default) }}
								<i
									v-if="feesInfo.variations.length"
									v-b-tooltip.hover.top
									:title="feeVariationsText(feesInfo.variations)"
									class="fas fa-info-circle" />
							</p>
							<p
								v-for="(fee, index) in feesInfo.whole_cart"
								:key="index"
								class="my-0">
								{{ translate(`plus_fee_whole_cart_${fee.operator}`, { fee: fee.formatted_amount, qty: fee.qty }) }}
							</p>
						</div>
						<div
							v-else-if="mainHasExchange"
							class="mx-auto exchange-text text-center medium d-flex align-items-end">
							{{ mainExchangePrice }}*
						</div>
						<div
							v-if="mainHasExchange"
							class="mx-auto exchange-text text-left small d-flex align-items-end">
							{{ translate('exchange_disclaimer') }}
						</div>
						<div class="mt-2">
							<p class="text-muted h6 font-weight-bold">
								{{ BV }} BV
							</p>
						</div>
						<p class="text-muted h6 font-weight-bold">
							{{ computedPresentation }}
						</p>
						<hr>
						<div :style="!['xs', 'sm'].includes(windowWidth) ? 'display: none': ''">
							<image-carousel
								modal-id="mobile"
								:images="imgCarousel"
								:group-to-show="getGroupToShow"
								:enable-zoom="true" />
						</div>
						<!-- Presentation -->
						<option-select
							:title="translate('bundle')"
							:options="getBundles"
							:selected-option.sync="selectedProduct.bundle"
							:initial-selected-option="selectedProduct.bundle" />
						<!-- Individual Purchase -->
						<div
							v-if="productNote"
							class="row my-3">
							<div class="col">
								<div class="row no-gutters">
									<div class="col">
										<b-alert
											show
											variant="warning"
											class="mb-0 text-dark">
											{{ translate(productNote) }}
										</b-alert>
									</div>
								</div>
							</div>
						</div>
						<!-- Individual Purchase -->
						<div
							v-if="isForIndividualPurchase"
							class="row my-3">
							<div class="col">
								<div class="row no-gutters">
									<div class="col">
										<b-alert
											show
											variant="warning"
											class="mb-0 text-dark">
											{{ translate('product_for_individual_sale') }}
										</b-alert>
									</div>
								</div>
							</div>
						</div>
						<!-- Not Individual Purchase -->
						<div
							v-if="isNotForIndividualPurchase"
							class="row my-3">
							<div class="col">
								<div class="row no-gutters">
									<div class="col">
										<b-alert
											show
											variant="warning"
											class="mb-0 text-dark">
											{{ translate('product_not_for_individual_sale') }}
										</b-alert>
									</div>
								</div>
							</div>
						</div>
						<!-- Shipping Date Alert -->
						<div
							v-if="shippingDateAlert"
							class="row my-3">
							<div class="col">
								<div class="row no-gutters">
									<div class="col">
										<b-alert
											show
											variant="info"
											class="mb-0 text-dark">
											{{ translate('shipping_date_alert') }}
										</b-alert>
									</div>
								</div>
							</div>
						</div>
						<!-- Minimun Purchase alert -->
						<div
							v-if="minPurchaseAlert"
							class="row my-3">
							<div class="col">
								<div class="row no-gutters">
									<div class="col">
										<b-alert
											show
											variant="warning"
											class="mb-0 text-dark">
											{{ minPurchaseAlertMsg }}
										</b-alert>
									</div>
								</div>
							</div>
						</div>
						<!-- Product out of stock -->
						<div
							v-if="productSoldOut"
							class="row">
							<div class="col-md-7 col-sm-12 col-xs-12 align-self-center">
								<button
									class="mb-0 btn btn-block btn-disabled disabled">
									{{ translate(`sold_out`) }}
								</button>
							</div>
						</div>
						<div
							v-if="productOutOfStock && !productSoldOut"
							class="row">
							<div class="col-md-7 col-sm-12 col-xs-12 align-self-center">
								<button
									class="mb-0 btn btn-block btn-disabled disabled">
									{{ translate(`out_of_stock`) }}
								</button>
							</div>
						</div>
						<!-- Product qty/add btn -->
						<template v-else-if="!productOutOfStock && !productSoldOut">
							<div
								v-if="availableBundles > 0"
								class="fw-bold"
								v-html="translate('only_x_left', { qty: availableBundles })" />
							<div
								:class="'pt-3'"
								class="row">
								<div class="align-self-center w-auto mb-3">
									<number-input-spinner
										v-model="selectedProduct.quantity"
										:mouse-down-speed="500"
										:min="MIN_QTY"
										:max="setMaxQty"
										:button-class="'vnis__button bg-primary-alt'"
										:input-class="'vnis__input vnis-custom-input-width'"
										:integer-only="true"
										class="vnis-smaller col" />
								</div>
								<div
									:class="['xs','sm'].includes(windowWidth) ? 'text-right' : ''"
									class="col align-self-center mb-3">
									<button
										:class="disableAddBtn ? 'disabled' : ''"
										:disabled="disableAddBtn"
										style="font-weight: 600;"
										class="btn p-2 pointer custom-btn custom-primary-btn branded-button"
										@click="disableAddBtn ? null : addProduct(getProductSku)">
										<template v-if="addProductsLoading">
											<i class="fa fa-fw fa-spinner fa-pulse" /> {{ translate('processing') }}
										</template>
										<template v-else>
											{{ translate('add_to_cart') }}
										</template>
									</button>
								</div>
							</div>
						</template>
					</div>
				</div>
				<!-- Tabs -->
				<div
					:class="['xs','sm', 'md'].includes(windowWidth) ? ' px-3' : 'px-5'"
					class="row mx-auto container mb-5">
					<div class="col-12">
						<b-tabs
							id="product-details-tabs"
							v-model="tabIndex">
							<b-tab
								v-for="(tabInfo, index) in computedTabInfo"
								:key="index"
								:title="translate(index)">
								<!-- eslint-disable-next-line -->
								<p v-html="tabInfo" />
							</b-tab>
						</b-tabs>
					</div>
				</div>
			</div>
			<!-- Related products -->
			<div v-if="$user.auth()">
				<div class="mx-auto container mt-5 mb-5">
					<hr>
				</div>
				<div v-if="mainPromoLoading || relatedProductsLoading">
					<is-loading
						:loading="mainPromoLoading || relatedProductsLoading"
						:loading-label="translate('loading')"
						:no-data-label="translate('data_not_found')" />
				</div>
				<div
					v-else
					:class="['xs','sm', 'md'].includes(windowWidth) ? ' px-3' : 'px-5'"
					class="row mx-auto container">
					<div class="col-12 text-center">
						<p class="text-muted h2 bold-text">
							{{ translate('related_products') }}
						</p>
					</div>
					<div
						class="py-4 w-100"
						:class="['xs','sm'].includes(windowWidth) ? 'scrolling-wrapper' : 'row'">
						<div
							v-for="(item, index) in relatedProductsData"
							:key="index"
							class="text-center my-3"
							:class="['xs','sm'].includes(windowWidth) ? 'd-inline-block' : 'col-md'">
							<img
								:src="item.image"
								:class="['xs','sm'].includes(windowWidth) ? 'img-fluid ' : 'img-max-width'"
								class="mx-auto mb-3 px-4 pointer"
								alt="Product image"
								@click="$router.push({ name: 'ProductDetails', params: { productName: item.product_name } })">
							<div class="mt-2">
								<p class="h6 text-muted bolder-title">
									{{ translate(item.code_name) }}
								</p>
								<p class="h4">
									{{ getProductPrice(item) }}
								</p>
								<!-- <p
									v-if="item.exchange"
									class="exchange-text h6">
									{{ item.exchange_price }}
								</p> -->
								<button
									type="button"
									:class="['md'].includes(windowWidth) ? ' w-75' : 'w-50'"
									class="btn btn-rounded mt-3 branded-button-outline"
									@click="$router.push({ name: 'ProductDetails', params: { productName: item.product_name } })">
									{{ translate('see_more') }}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import cookie from 'vue-cookie';
import NumberInputSpinner from 'vue-number-input-spinner';
import WindowSizes from '@/mixins/WindowSizes';
import { MIN_QTY, MAX_QTY } from '@/settings/Cart';
import { NOT_FOUND } from '@/settings/Errors';
import {
	SORTED_PRODUCT_SIZES,
	PRODUCT_MAX_QTY,
	PRODUCT_SKU_MAX_QTY,
	PRODUCT_THEME,
} from '@/settings/Products';
import { META_DESCRIPTION } from '@/settings/Meta';
import {
	Store, Products as ProductsTranslations, Events,
} from '@/translations';
import Cart from '@/util/Cart';
import EventBus from '@/util/eventBus';
import { trackEvent } from '@/util/GoogleTagManager';
import Products from '@/util/Products';
import isLoading from '@/components/Loading';
import ImageCarousel from '@/components/ProductImageCarousel';
import OptionSelect from '@/components/OptionSelect';
import EarlyLaunch from '@/mixins/EarlyLaunch';
import PublicStore from '@/mixins/PublicStore';

export default {
	name: 'PromotionDetails',
	metaInfo() {
		const content = META_DESCRIPTION[`product_detail_${this.$route.params.promoName.toLowerCase()}`];
		if (content !== undefined) {
			return {
				meta: [
					{ vmid: 'description', name: 'description', content },
				],
			};
		}
		return {};
	},
	messages: [Store, ProductsTranslations, Events],
	components: {
		OptionSelect,
		NumberInputSpinner,
		isLoading,
		ImageCarousel,
	},
	mixins: [WindowSizes, EarlyLaunch, PublicStore],
	data() {
		return {
			NOT_FOUND,
			mainPromo: new Products(),
			relatedProducts: new Products(),
			addProducts: new Cart(),
			alert: new this.$Alert(),
			SORTED_PRODUCT_SIZES,
			MIN_QTY,
			MAX_QTY,
			PRODUCT_MAX_QTY,
			PRODUCT_SKU_MAX_QTY,
			selectedProduct: {
				quantity: MIN_QTY,
				bundle: '',
				note: null,
			},
			tabIndex: 0,
			topOffset: 0,
		};
	},
	computed: {
		productFromUrl() {
			try {
				return this.mainPromo.data.response.data.data;
			} catch (e) {
				return {};
			}
		},
		mainPromoData() {
			try {
				const data = this.productFromUrl;
				const description = `${data.attributes.code_name}_description`;
				return {
					id: data.attributes.bo_id,
					sku: data.attributes.sku,
					code_name: data.attributes.code_name,
					price: this.getProductPrice(data.attributes),
					image: data.attributes.image,
					presentation: `${data.attributes.code_name}_presentation`,
					children: data.attributes.children,
					thumbnail: data.attributes.thumbnail,
					exchange: data.attributes.exchange,
					exchange_price: data.attributes.exchange_price,
					out_of_stock: data.attributes.out_of_stock,
					description,
					sold_out: data.attributes.sold_out,
					fees: data.attributes.fees,
				};
			} catch (error) {
				return [];
			}
		},
		mainHasExchange() {
			return this.mainExchangePrice !== '';
		},
		mainExchangePrice() {
			try {
				const data = this.productFromUrl;
				return data.find((item) => item.attributes.code_name === this.selectedProduct.bundle).attributes.price_exchange ?? '';
			} catch (error) {
				return '';
			}
		},
		mainPromoLoading() {
			return !!this.mainPromo.data.loading;
		},
		getGroupToShow() {
			try {
				const data = this.productFromUrl;
				return data.find((item) => item.attributes.code_name === this.selectedProduct.bundle).attributes.code_name;
			} catch (error) {
				return '';
			}
		},
		imgCarousel() {
			try {
				const data = this.productFromUrl;
				return data.reduce((acc, item) => {
					acc[item.attributes.code_name] = [{ image: item.attributes.img, thumbnail: item.attributes.img }];
					return acc;
				},	{});
			} catch (error) {
				return {};
			}
		},
		relatedProductsData() {
			try {
				const { data } = this.relatedProducts.data.response.data;
				let response = data.filter((obj) => (this.mainPromoData.sku !== obj.attributes.sku)).slice(0, 3);
				response = response.map((obj) => ({
					sku: obj.attributes.sku,
					code_name: obj.attributes.code_name,
					price: obj.attributes.price,
					image: obj.attributes.image,
					product_name: obj.attributes.decoded_name,
					exchange: obj.attributes.exchange,
					exchange_price: obj.attributes.exchange_price,
				}));
				return response;
			} catch (error) {
				return [];
			}
		},
		relatedProductsLoading() {
			return !!this.relatedProducts.data.loading;
		},
		addProductsLoading() {
			return !!this.addProducts.data.loading;
		},
		getBundles() {
			try {
				const data = this.productFromUrl;
				const availableBundles = data.map((item) => item.attributes.code_name);
				if (availableBundles.length === 0) {
					this.returnToStore();
				}
				return availableBundles.map((value) => ({ value, text: this.translate(`${value}`) }));
			} catch (error) {
				return [];
			}
		},
		getProductSku() {
			try {
				const data = this.productFromUrl;
				if (this.selectedProduct.bundle) {
					const { products } = data.find((item) => item.attributes.code_name === this.selectedProduct.bundle).attributes;
					const skus = {};
					Object.keys(products).forEach((sku) => {
						skus[sku] = products[sku] * this.selectedProduct.quantity;
					});
					return skus;
				}
				return {};
			} catch (error) {
				return {};
			}
		},
		productOutOfStock() {
			try {
				const data = this.productFromUrl;
				return data.find((item) => item.attributes.code_name === this.selectedProduct.bundle).attributes.out_of_stock ?? false;
			} catch (error) {
				return true;
			}
		},
		showAvailableBundles() {
			try {
				const data = this.productFromUrl;
				const bundle = data.find((item) => item.attributes.code_name === this.selectedProduct.bundle);
				return bundle.attributes.show_available_bundles ?? false;
			} catch (error) {
				return false;
			}
		},
		BV() {
			try {
				const data = this.productFromUrl;
				const bundle = data.find((item) => item.attributes.code_name === this.selectedProduct.bundle);
				return bundle.attributes.bv ?? 0;
			} catch (error) {
				return 0;
			}
		},
		availableBundles() {
			try {
				const data = this.productFromUrl;
				const bundle = data.find((item) => item.attributes.code_name === this.selectedProduct.bundle);
				if (bundle.attributes.show_available_bundles === false) return 0;
				return Math.max(bundle.attributes.available_bundles ?? 0, 0);
			} catch (error) {
				return 0;
			}
		},
		productNote() {
			try {
				const data = this.productFromUrl;
				return data.find((item) => item.attributes.code_name === this.selectedProduct.bundle).attributes.note ?? null;
			} catch (error) {
				return null;
			}
		},
		productSoldOut() {
			return false;
		},
		setMaxQty() {
			try {
				if (this.showAvailableBundles) {
					return this.availableBundles;
				}
			} catch (error) {
				// do nothing
			}
			return this.MAX_QTY;
		},
		isForIndividualPurchase() {
			try {
				const data = this.productFromUrl;
				const options = this.getProductOptions(this.getProductSku);
				return options.individualPurchase || data.attributes.individual_purchase;
			} catch (error) {
				return false;
			}
		},
		isNotForIndividualPurchase() {
			try {
				const data = this.productFromUrl;
				const options = this.getProductOptions(this.getProductSku);
				return options.notIndividualPurchase || data.attributes.not_individual_purchase;
			} catch (error) {
				return false;
			}
		},
		shippingDateAlert() {
			try {
				const data = this.productFromUrl;
				const options = this.getProductOptions(this.getProductSku);
				return options.customShippingDate || data.attributes.custom_shipping_date;
			} catch (error) {
				return false;
			}
		},
		disableAddBtn() {
			return this.addProductsLoading;
		},
		computedTitle() {
			let title = `${this.getGroupToShow}_title`;
			const customTranslateKey = this.customTranslateKey(title);
			if (typeof customTranslateKey !== 'undefined' && customTranslateKey.title) {
				title = customTranslateKey.title;
			}
			return this.translate(title);
		},
		computedPresentation() {
			const data = this.mainPromoData;
			let { presentation } = data;
			const customTranslateKey = this.customTranslateKey(data.sku);
			if (typeof customTranslateKey !== 'undefined' && customTranslateKey.presentation) {
				presentation = customTranslateKey.presentation;
			}
			return this.translate(presentation);
		},
		computedPrice() {
			try {
				const data = this.productFromUrl;
				return data.find((item) => item.attributes.code_name === this.selectedProduct.bundle).attributes.price;
			} catch (error) {
				return '';
			}
		},
		computedPromoPrice() {
			return '';
		},
		computedPriceInfo() {
			const { sku } = this.mainPromoData;
			const customTranslateKey = this.customTranslateKey(sku);
			if (typeof customTranslateKey !== 'undefined' && customTranslateKey.priceInfo) {
				return this.translate(customTranslateKey.priceInfo);
			}
			return '';
		},
		feesInfo() {
			try {
				if (Object.keys(this.mainPromoData.children).length > 0) {
					const sku = Object.keys(this.getProductSku)[0];
					return this.mainPromoData.children[sku].fees;
				}
				return this.mainPromoData.fees;
			} catch (e) {
				// Do nothing
			}

			return null;
		},
		computedTabInfo() {
			const selectedOption = this.getGroupToShow;
			if (selectedOption === '') {
				return { details: '' };
			}

			const data = this.productFromUrl;
			const descriptionItem = data.find((item) => item.attributes.code_name === this.selectedProduct.bundle).attributes.details;

			let description = `${descriptionItem}_description`;
			const customTranslateKey = this.customTranslateKey(selectedOption);
			if (typeof customTranslateKey !== 'undefined' && customTranslateKey.description) {
				if (customTranslateKey.description.translated) {
					return { details: customTranslateKey.description.translated };
				}
				description = customTranslateKey.description;
			}
			return { details: this.translate(description) };
		},
		minPurchaseAlert() {
			try {
				const data = this.productFromUrl;
				const options = this.getProductOptions(this.getProductSku);

				return options.minPurchaseQty.restricted || data.attributes.minimun_purchase_qty.restricted;
			} catch (error) {
				return false;
			}
		},
		minPurchaseAlertMsg() {
			try {
				const data = this.productFromUrl;
				const options = this.getProductOptions(this.getProductSku);

				if (options.minPurchaseQty.restricted) {
					return options.minPurchaseQty.message_code_name;
				}
				return this.translate(data.attributes.minimun_purchase_qty.message_code_name, {
					qty: data.attributes.minimun_purchase_qty.min_qty,
				});
			} catch (error) {
				return '';
			}
		},
		themeColors() {
			const { promoName } = this.$route.params;
			const theme = PRODUCT_THEME[promoName.toLowerCase()] ?? PRODUCT_THEME.default;

			return {
				'--theme-primary-color': theme.color,
				'--theme-primary-color-hover': theme.hoverColor,
				'--theme-primary-color-shadow': theme.focusColor,
			};
		},
	},
	watch: {
		async country() {
			this.initPromoInfo();
		},
		setMaxQty(value) {
			if (this.selectedProduct.quantity > value) this.selectedProduct.quantity = value;
		},
		'selectedProduct.bundle': {
			handler(bundle) {
				if (typeof bundle !== 'undefined') {
					// eslint-disable-next-line no-restricted-globals
					history.pushState(null, null, `?bundle=${bundle}`);
				}
			},
		},
	},
	mounted() {
		EventBus.$on('isReplicatedBarShown', (shown) => {
			this.topOffset = shown && !this.$user.auth() ? 55 : 0;
		});
		if (!this.showPublicStore) {
			this.$router.replace({ name: 'Store' });
			return;
		}
		if (this.earlyLaunch) {
			this.returnToStore();
			return;
		}
		this.initPromoInfo();
	},
	beforeDestroy() {
		EventBus.$off('isReplicatedBarShown');
	},
	methods: {
		initPromoInfo() {
			const { promoName } = this.$route.params;

			return this.mainPromo.getPromotionDetails(promoName).then((response) => {
				const { category_code: categoryCode } = response.attributes;

				this.selectInitialConfiguration();

				return this.$user.auth() ? this.relatedProducts.getProducts(this.getStoredCountry(), categoryCode) : [];
			}).catch((error) => {
				if (this.NOT_FOUND.includes(error.status)) this.$router.push({ name: 'Store' });
			});
		},
		selectInitialConfiguration() {
			const {
				bundle,
			} = this.$route.query;
			this.selectedProduct.bundle = this.getBundles.find((item) => item.value === bundle)?.value || this.getBundles[0]?.value;
			this.selectedProduct.note = this.getBundles.find((item) => item.value === bundle)?.note || this.getBundles[0]?.note;
		},
		getProductOptions(products) {
			let image = this.mainPromoData.thumbnail;
			let outOfStock = false;
			let soldOut = false;
			let individualPurchase = false;
			let notIndividualPurchase = false;
			let customShippingDate = false;
			let minPurchaseQty = {};
			if (Object.keys(this.mainPromoData.children).length) {
				const { children } = this.mainPromoData;
				Object.keys(products).forEach((sku) => {
					image = children[sku].thumbnail;
					outOfStock = children[sku].out_of_stock;
					individualPurchase = children[sku].individual_purchase;
					notIndividualPurchase = children[sku].not_individual_purchase;
					customShippingDate = children[sku].custom_shipping_date;
					minPurchaseQty = children[sku].minimun_purchase_qty;
				});
			} else {
				outOfStock = this.mainPromoData.out_of_stock;
				soldOut = this.mainPromoData.sold_out;
			}
			return {
				image,
				outOfStock,
				individualPurchase,
				notIndividualPurchase,
				customShippingDate,
				minPurchaseQty,
				soldOut,
			};
		},
		async addProduct(products) {
			if (!this.addProductsLoading) {
				try {
					await this.addProducts.addCartProducts(cookie.get('cart_id'), { products, sponsor_id: this.$replicated.replicatedSponsor() });
					trackEvent(this.$gtm, 'productQtyUpdated'); // TODO: track product quantity and sku
					EventBus.$emit('updateLayoutStoreTotals');
				} catch (e) {
					if (typeof this.addProducts.errors.errors.products !== 'undefined') {
						let response = '';
						this.addProducts.errors.errors.products.forEach((item) => { response += `${item} \n`; });
						this.alert.toast('error', response, { timer: 4000 });
					} else {
						this.alert.toast('error', this.translate('default_error_message'));
					}
				}
			}
		},
		getProductPrice(product) {
			return product.price.formatted_amount;
		},
		returnToStore() {
			this.$router.push({ name: 'Store' });
		},
		customTranslateKey(sku) {
			switch (sku) {
			case 'am_essentials_powder': {
				return { description: 'am_essentials_description' };
			}
			case 'pm_essentials_powder': {
				return { description: 'pm_essentials_description' };
			}
			case 'am_essentials_caplets': {
				return { description: 'am_essentials_description' };
			}
			case 'pm_essentials_caplets': {
				return { description: 'pm_essentials_description' };
			}
			case 'finiti_caplets': {
				return { description: 'finiti_description' };
			}
			case 'revita_powder': {
				return { description: 'revita_description' };
			}
			case 'mind_packets': {
				return { description: 'mind_description' };
			}
			case 'life_cirqul8': {
				return { description: 'life_description' };
			}
			case 'am_pm_bundle': {
				return { title: 'am_pm_bundle_title' };
			}
			default: return undefined;
			}
		},
		subtitle() {
			return '';
		},
		renderDefaultFee(fees) {
			const texts = Object.keys(fees).map((feeName) => {
				const fee = fees[feeName];
				return this.translate(`plus_${feeName}_with_value`, { value: fee.formatted_amount });
			});
			return `+ ${texts.join(' + ')}`;
		},
		renderDefaultFeeExchange(fees) {
			const texts = Object.keys(fees).map((feeName) => {
				const fee = fees[feeName];
				return this.translate(`plus_${feeName}_with_value`, { value: fee.exchange.total });
			});
			return `+ ${texts.join(' + ')}`;
		},
		feeVariationsText(variations) {
			const texts = [];
			variations.forEach((variation) => {
				texts.push(this.translate('plus_fee_variation', { fee: variation.fees.importation_fee.formatted_amount, qty: variation.required_qty }));
			});
			return texts.join(' ');
		},
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			if (vm.$user.details().hide_store) {
				vm.$router.replace({ name: 'Store' });
			}
		});
	},
};
</script>
<style scoped>
.h-55 {
	height: 55px;
}
.h-80 {
	height: 80px;
}
.custom-btn.custom-primary-btn{
	width: 150px;
	border-radius: 25px;
}

.img-max-width{
	max-width: 250px;
}
</style>
<style>
#theme .vnis-custom-input-width{
	width: 40px;
}
.btn-disabled.disabled{
	background: #777777b5;
    border-color: rgba(130, 130, 130, 0.709804);
    color: #FFFFFF;
}
.btn-disabled.disabled:hover{
	background: #777777b5;
    border-color: rgba(130, 130, 130, 0.709804);
    color: #FFFFFF;
}
.bolder-title {
	-webkit-text-stroke: 1.3px;
	font-size: 1.2em;
}
#theme .btn-rounded {
	border-radius: 25px;
}
/* Tabs */
#product-details-tabs ul {
	border-bottom: 2px solid #c8ced3;
}
#product-details-tabs li a{
	padding-right: 1em;
	padding-bottom: .5em;
	margin-right: 1.5em;
}
#product-details-tabs li a.active {
	border-bottom: 3px solid #eb772f;
	border-width: thick;
}
#product-details-tabs .tab-content {
	padding: 2em 0em 2em 0em;
	border: none;
}

/* Tabs title color */
#product-details-tabs li a {
	color: var(--theme-primary-color);
}
#product-details-tabs li a.active {
	border-bottom-color: var(--theme-primary-color);
}

/* Quantity selector button color */
.vnis__button.bg-primary-alt:hover,
.vnis__button.bg-primary-alt:not(:disabled):not(.disabled):active {
	background: var(--theme-primary-color) !important;
}

/* Branded pagination buttons */
.page-item {
	&.active .page-link {
		background: var(--theme-primary-color);
	}

	.page-link {
		color: var(--theme-primary-color);

		&:focus {
			box-shadow: 0 0 0 0.2rem var(--theme-primary-color-shadow) !important;
		}
	}
}
</style>
